import { createRouter, createWebHashHistory } from "vue-router";
import RentCollections from "../views/RentCollections.vue";
import Overview from "../views/Overview.vue";
import Advertising from "../views/Advertising.vue";
import ComingSoon from "../views/ComingSoon.vue";
import NtvAndMoveouts from "../views/NtvAndMoveouts.vue";
import VacatingAndTurning from "../views/VacatingAndTurning.vue";
import MoveIns from "../views/MoveIns.vue";
import LeaseRenewal from "../views/LeaseRenewal.vue";
import WorkOrders from "../views/WorkOrders.vue";
import TermsOfService from "../views/TermsOfService.vue";

export default createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active-link",
  linkExactActiveClass: "exact-active-link",
  routes: [{
    path: "/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/rentcollections",
    name: "RentCollections",
    component: RentCollections,
  },
  {
    path: "/advertising",
    name: "Advertising",
    component: Advertising,
  },
  {
    path: "/vacating-and-turning",
    name: "VacatingAndTurning",
    component: VacatingAndTurning,
  },
  {
    path: "/ntv-and-moveouts",
    name: "NtvAndMoveouts",
    component: NtvAndMoveouts,
  },
  {
    path: "/move-ins",
    name: "MoveIns",
    component: MoveIns,
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
  },
  {
    path: "/work-orders",
    name: "WorkOrders",
    component: WorkOrders,
  },
  {
    path: "/lease-renewal",
    name: "LeaseRenewal",
    component: LeaseRenewal,
  },
  {
    path: "/tos",
    name: "TOS",
    component: TermsOfService,
  },
  {
    path: "/:catchAll(.*)",
    name: "Redirect",
    component: Overview,
  }],
});

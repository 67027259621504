<template>
  <v-app class="mainBackgroundColor">
    <v-app-bar
      app
      color="#013368"
      class="elevation-0"
    >
      <template #prepend>
        <v-img
          src="/pure-p-logo.png"
          width="83"
          alt="Company Logo"
        />
      </template>
      <v-toolbar-title class="toolbar-title-margin">
        <span class="ml-6 text-h6 text-white">PURE Institutional Management</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-app-bar
      v-show="notHidden"
      elevation="0"
    >
      <div class="d-flex align-baseline tabs-left-margin">
        <router-link
          to="/overview"
          active-class="active"
          :class="{ inactive: $route.path != '/overview' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Overview
          </v-btn>
        </router-link>
        <router-link
          to="/rentCollections"
          active-class="active"
          :class="{ inactive: $route.path != '/rentCollections' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Rent Collections
          </v-btn>
        </router-link>
        <router-link
          to="/advertising"
          active-class="active"
          :class="{ inactive: $route.path != '/advertising' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Advertising
          </v-btn>
        </router-link>
        <router-link
          to="/vacating-and-turning"
          active-class="active"
          :class="{ inactive: $route.path != '/vacating-and-turning' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Vacating and Turning
          </v-btn>
        </router-link>
        <router-link
          to="/ntv-and-moveouts"
          active-class="active"
          :class="{ inactive: $route.path != '/ntv-and-moveouts' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            NTV and Move-outs
          </v-btn>
        </router-link>
        <router-link
          to="/move-ins"
          active-class="active"
          :class="{ inactive: $route.path != '/move-ins' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Move-ins
          </v-btn>
        </router-link>
        <router-link
          to="/coming-soon"
          active-class="active"
          :class="{ inactive: $route.path != '/coming-soon' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Coming Soon
          </v-btn>
        </router-link>
        <router-link
          to="/work-orders"
          active-class="active"
          :class="{ inactive: $route.path != '/work-orders' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Work Orders
          </v-btn>
        </router-link>
        <router-link
          to="/lease-renewal"
          active-class="active"
          :class="{ inactive: $route.path != '/lease-renewal' }"
          style="text-decoration: none"
        >
          <v-btn class="capitalize">
            Lease Renewal
          </v-btn>
        </router-link>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";

console.log('revision #2');

export default {
  name: "App",
  components: {
    Footer,
  },
  computed: {
    notHidden() {
      return this.$store.state.showBar;
    },
  },
};
</script>

<style scoped>
.exact-active-link {
  color: #2a6cf9;
  font-weight: bold;
  text-decoration: underline !important;
}
.inactive {
  color: #636c82;
}
.capitalize {
  text-transform: capitalize !important;
}
.mainBackgroundColor {
  background-color: #f5f5f5;
}
.tabs-left-margin {
  margin-left: 60px;
}
.toolbar-title-margin {
  margin-left: -30px !important;
}
.tabs-align {
  padding-top: 40px !important;
}
</style>

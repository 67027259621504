import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import { createStore } from "vuex";

// Create a new store instance.
const store = createStore({
  state () {
    return {
      showBar: false,
    };
  },
  mutations: {
    showBar (state) {
      state.showBar = true;
    },
  },
});

loadFonts();

createApp(App).use(vuetify).use(router).use(store).mount("#app");

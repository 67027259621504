<template>
  <div class="full-width">
    <span
      v-show="notHidden"
      class="page-title"
    >Rent Collections</span>
    <div
      ref="tab"
      class="full-width mb-6 pb-6 relative"
    />
  </div>
</template>
<script>
import { TableauViz,
  TableauEventType } from "https://tableau.purepm.co/javascripts/api/tableau.embedding.3.latest.min.js";

export default {
  name: "Overview",
  data() {
    return {};
  },
  computed: {
    notHidden() {
      return this.$store.state.showBar;
    },
  },
  mounted() {
    const viz = new TableauViz();
    viz.src = "https://tableau.purepm.co/#/views/RentCollected/RentCollections";
    viz.toolbar = "hidden";
    viz.id = "testFrame";
    viz.hideTabs = "true";
    viz.addEventListener(TableauEventType.FirstInteractive, (event) => {
      this.$store.commit("showBar");
    });
    this.$refs.tab.appendChild(viz);
  },
};
</script>

<style scoped src="../assets/css/chartviews.css"></style>
